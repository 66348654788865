<template>
  <div class="shangwu-wrap">
    <div class="title">线上调研问卷</div>
    <div class="shangwu-list">
      <div class="shangwu-item">
        <div class="shangwu-name">您的称呼</div>
        <div class="shangwu-content">
          <el-input type="text" class="content-input" v-model="name" placeholder="请填写您的姓名"></el-input>
          <span class="uinit">先生/女士</span>
        </div>
      </div>
      <div class="shangwu-item">
        <div class="shangwu-name">联系方式</div>
        <div class="shangwu-content">
          <el-input type="text" maxlength="11" v-model="phone" placeholder="请填写您的电话号码"></el-input>
        </div>
      </div>
      <div class="shangwu-item">
        <div class="shangwu-name">您的角色</div>
        <div class="shangwu-content">
          <el-select class="content-select" v-model="role" placeholder="请选择您的角色">
            <el-option label="政企机构" value="0"></el-option>
            <el-option label="地产开发商" value="1"></el-option>
            <el-option label="物业产权业主" value="2"></el-option>
            <el-option label="中介机构" value="3"></el-option>
            <el-option label="项目承租方" value="4"></el-option>
          </el-select>
        </div>
      </div>
      <div class="shangwu-item">
        <div class="shangwu-name">项目城市</div>
        <div class="shangwu-content">
          <el-select class="content-select" v-model="city" placeholder="请选择您的项目所在的城市">
            <el-option label="北京" value="0"></el-option>
            <el-option label="深圳" value="1"></el-option>
            <el-option label="上海" value="2"></el-option>
            <el-option label="广州" value="3"></el-option>
            <el-option label="杭州" value="4"></el-option>
            <el-option label="东莞" value="5"></el-option>
          </el-select>
        </div>
      </div>
      <div class="shangwu-item">
        <div class="shangwu-name">意向合作方式</div>
        <div class="shangwu-content">
          <el-select class="content-select" v-model="cooperate" placeholder="请选择您意向的合作方式">
            <el-option label="物业直营" value="0"></el-option>
            <el-option label="加盟托管" value="1"></el-option>
            <el-option label="特许经营" value="2"></el-option>
            <el-option label="物业出售" value="3"></el-option>
            <el-option label="租赁权转让" value="4"></el-option>
          </el-select>
        </div>
      </div>
      <div class="shangwu-item">
        <div class="shangwu-name">项目建筑面积</div>
        <div class="shangwu-content">
          <el-input type="digit" v-model="area" placeholder="请填写建筑面积"></el-input>
          <span class="uinit left">平方米</span>
        </div>
      </div>
      <div class="shangwu-item">
        <div class="shangwu-name">项目状态</div>
        <div class="shangwu-content">
          <el-select class="content-select" v-model="status" placeholder="请选择您的项目状态">
            <el-option label="待开发" value="0"></el-option>
            <el-option label="已运营" value="1"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="tips-wrapper" v-if="showTips">
      <div class="tips">
        <div class="tips-title">提交成功</div>
        <div class="tips-content">感谢垂询，专属商务经理会及时联系您</div>
        <div class="tips-close" @click="confrim">确 定</div>
      </div>
    </div>
    <div class="submit-btn" @click.stop="handleSubmit">
      <span>提 交</span>
    </div>
  </div>
</template>

<script>
import { postMessage } from '../../request'
export default {
  data () {
    return {
      name: '',
      phone: '',
      role: '',
      city: '',
      cooperate: '',
      area: '',
      status: '',
      showTips: false
    }
  },
  methods: {
    confrim () {
      Object.assign(this.$data, this.$options.data())
    },
    handleSubmit () {
      if (!this.name || !this.phone || !this.area) {
        return this.$message('请填写姓名和联系方式')
      }
      if (!this.role || !this.city || !this.city || !this.cooperate || !this.status) {
        return this.$message('请您完整选择表单')
      }
      let data = {
        name: this.name,
        phone: this.phone,
        status: this.status,
        area: this.area,
        owner: this.role,
        address: this.city,
        cooperation: this.cooperate
      }
      postMessage(data).then(res => {
        if (res.code == 0) {
          this.showTips = true
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'warning'
          });
        }
      })
    }
  }

}
</script>

<style lang="less" scoped>
.shangwu-wrap {
  .tips-wrapper {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    .tips {
      background: #ffffff;
      border-radius: 0.2rem;
      width: 9.5rem;
      .tips-title {
        margin-top: 0.3rem;
        text-align: center;
        font-size: 0.6rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .tips-content {
        text-align: center;
        padding: 0.8rem 0;
        border-bottom: 1px solid #f1f1f1;
        font-size: 0.5rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
      }
      .tips-close {
        text-align: center;
        font-size: 0.6rem;
        padding: 0.3rem 0;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .title {
    margin-top: 0.5rem;
    text-align: center;
    width: 15rem;
    font-size: 0.8rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .shangwu-list {
    width: 14rem;
    overflow: hidden;
    margin: 0.2rem auto;
    padding-bottom: 3rem;
  }
  .shangwu-item {
    margin-top: 0.5rem;
    .shangwu-name {
      font-size: 0.6rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .shangwu-content {
      margin-top: 0.4rem;
      display: flex;
      align-items: center;

      .uinit {
        display: inline-block;
        height: 1.8rem;
        line-height: 1.8rem;
        font-size: 0.5rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #999999;
        margin-left: -3.25rem;
        z-index: 99;
        &.left {
          margin-left: -2.4rem;
        }
      }
      .content-select {
        width: 100%;
        height: 1.8rem;
      }
    }
  }
  .submit-btn {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 2rem;
    font-size: 0.65rem;
    line-height: 2rem;
    color: #ffffff;
    text-align: center;
    background: #ff2817;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
  }
}
</style>