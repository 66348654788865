<template>
  <div class="contact-wrapper" id="contact">
    <div class="contact">
      <section-title :isLight="true" :maxTitle="'商务合作'" :minTitle="'Business Cooperation'" />
      <div class="contact-details-wrapper">
        <div class="contact-details">
          <div class="contact-title">租我家 您的专属物业管家</div>
          <div class="contact-item">
            <el-input type="text" v-model="name" placeholder="您的称呼"></el-input>
          </div>
          <div class="contact-item">
            <el-input type="tel" maxlength="11" v-model="phone" placeholder="联系方式"></el-input>
          </div>
          <div class="contact-item">
            <el-select class="contact-select" v-model="room" placeholder="物业体量">
              <el-option label="小于100间" value="0"></el-option>
              <el-option label="100-500间" value="1"></el-option>
              <el-option label="500间以上" value="2"></el-option>
            </el-select>
          </div>
          <div class="contact-item">
            <el-select class="contact-select" v-model="status" placeholder="物业状态">
              <el-option label="筹建中" value="0"></el-option>
              <el-option label="运营中" value="1"></el-option>
            </el-select>
          </div>
          <div class="contact-item">
            <el-select class="contact-select" v-model="attribution" placeholder="物业性质">
              <el-option label="承租" value="0"></el-option>
              <el-option label="自有" value="1"></el-option>
            </el-select>
          </div>
          <div class="contact-item">
            <el-select class="contact-select" v-model="position" placeholder="所在城市">
              <el-option label="深圳" value="0"></el-option>
              <el-option label="广州" value="1"></el-option>
              <el-option label="北京" value="2"></el-option>
              <el-option label="上海" value="3"></el-option>
              <el-option label="杭州" value="4"></el-option>
              <el-option label="其他" value="5"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="contact-submit">
        <el-button class="submit" @click="handleSubmit">联系我们</el-button>
      </div>
      <div class="copyright">
        <div>深圳市租我家科技有限公司</div>
        <div>© 2021 版权所有 | 粤ICP备16009271号</div>
      </div>
    </div>
  </div>
</template>

<script>
import { postMessage } from '../../request'
import SectionTitle from '../components/sectionTitle'
export default {
  name: 'contact',
  data () {
    return {
      name: '',
      phone: '',
      room: '',
      status: '',
      attribution: '',
      position: ''
    }
  },
  components: {
    SectionTitle,
  },
  methods: {
    handleSubmit () {
      if (!this.name || !this.phone || !this.status || !this.room || !this.attribution || !this.position) {
        this.$message.warning('请填写完整信息')
        return
      }
      if (this.phone.length !== 11) {
        this.$message.warning('请填写正确的手机号')
        return
      }

      postMessage({ name: this.name, phone: this.phone, amount: this.room, status: this.status, owner: this.attribution, address: this.position }).then(res => {
        if (res.code === 0) {
          this.$message.success(res.message)
        } else {
          this.$message.warning(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.contact-wrapper {
  width: 100%;
  height: auto;
  background-image: url('../../assets/images/m-end.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .contact {
    width: 14.2rem;
    margin: 0 auto;
    .contact-details-wrapper {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .contact-details {
        width: 90%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        .contact-title {
          width: 100%;
          text-align: center;
          font-size: 0.56rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin-bottom: 1rem;
        }
        .contact-item {
          width: 46%;
          margin-bottom: 0.4rem;
          .contact-select {
            width: 100%;
          }
        }
      }
    }
    .contact-submit {
      margin-top: 1rem;
      text-align: center;
      .submit {
        min-width: 150px;
        min-height: 40px;
        width: 6rem;
        height: 1.5rem;
        background-color: #e93a30;
        border-radius: 0.75rem;
        font-size: 0.5rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        border: 1px solid #e93a30;
      }
    }
    .copyright {
      text-align: center;
      margin-top: 1.4rem;
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>

