import Vue from "vue";
import Router from "vue-router";
import MShangwu from './pages/M-Shangwu.vue'
import MainPage from "./pages/main-page.vue"
import MDoc from './pages/M-Doc.vue'



Vue.use(Router)

const router = new Router({
    routes: [{
            path: '/',
            component: MainPage
        },
        {
            path: '/business',
            component: MShangwu
        },
        {
            path: '/doc',
            component: MDoc,

        }
    ]
})

export default router