<template>
  <div class="m-doc">
    <div class="m-doc-wrap" v-for="(doc, docIndex) in docList" :key="docIndex">
      <div class="m-doc-title">
        <span class="line"></span>
        <span class="text">第{{ docIndex + 1 }}部分：{{ doc.title }}</span>
        <span class="line"></span>
      </div>

      <div class="m-doc-content-wrap" v-for="(childDoc, childIndex) in doc.inventory" :key="childIndex">
        <div class="m-doc-content">
          <div class="doc-upload-text">
            <div class="doc-upload-title">
              {{ childDoc.name }}
            </div>
            <div class="doc-upload-des">
              备注：{{ childDoc.remarks }}
            </div>
          </div>
          <div class="doc-upload-control">
            <img class="wenjianjia" src="@/assets/images/wenjianjia.png" alt="文件夹">
            <div class="btn-text">上传文件</div>
            <input class="upload-input" type="file" @change="handleClick(childDoc, $event)" />
          </div>
        </div>

        <div class="line"></div>

        <div class="file-list" v-for="(file, fileIndex) in childDoc.file_path" :key="fileIndex">
          <div class="file-name" @click="handleBack(file)">
            <img class="icon-pdf" src="@/assets/images/icon-pdf.png" alt="图标" srcset="">
            <span>{{ fileName(file.path) }}</span>
          </div>
          <div class="delet" @click="handleDelet(file)">删除</div>

        </div>



      </div>
    </div>




  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { getDocList, postDoc, deletDoc } from '../../request'
export default {
  data () {
    return {
      wxToken: '',
      eventId: '',
      docList: null,
    }
  },
  created () {
    document.title = '提交资料'
    let query = this.$route.query

    this.wxToken = query.token || ''
    this.id = query.id || ''
    this._getDocList()

  },
  methods: {
    _getDocList () {
      getDocList({ token: this.wxToken, id: this.id }).then(res => {
        if (res.code == 0) {
          if (res.data.length == 0) {
            this.$message('后台未设置上传文件清单')
          } else {
            this.docList = res.data
          }
        } else {
          this.$message(res.message)
        }
      })
    },

    fileName (path) {
      let tempArr = path.split('/')
      return tempArr[tempArr.length - 1]
    },

    handleClick (doc, e) {
      console.log(doc)
      console.log(e)
      if (e.target.files.length > 0) {
        let formData = new FormData()
        formData.append('token', this.wxToken)
        formData.append('file', e.target.files[0])
        formData.append('target_id', doc.target_id)
        postDoc(formData).then(res => {
          console.log(res)
          if (res.code == 0) {
            this.$message.success(res.message)
            this._getDocList()
          } else {
            this.$message.error(res.message)
          }
        })
      }


    },
    handleBack (file) {
      wx.miniProgram.navigateTo({ url: `/pages/mine/file-download?path=${file.path}` })
    },
    handleDelet (file) {
      let data = {
        token: this.wxToken,
        id: file.id,
        target_id: file.target_id
      }
      deletDoc(data).then(res => {
        if (res.code == 0) {
          this.$message.success(res.message)
          this._getDocList()
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }


}
</script>

<style lang="less" scoped>
.m-doc {
  width: 100vw;
  min-height: 100vh;
  background: #f8f8f8;
  overflow: hidden;

  .m-doc-title {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .line {
      display: inline-block;
      width: 14px;
      height: 1px;
      background: #333333;
    }

    .text {
      height: 1rem;
      font-size: .6rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 1rem;
    }
  }

  .m-doc-content-wrap {
    width: 93.33%;
    margin: 0.4rem auto;
    background: #ffffff;

    .m-doc-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .doc-upload-text {
        width: 70%;
        margin-left: .5rem;
        padding-top: .5rem;

        .doc-upload-title {

          font-size: .6rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #444444;
          line-height: 1rem;
        }
        .doc-upload-des {
          font-size: 0.5rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 1rem;
        }
      }

      .doc-upload-control {
        width: 2.5rem;
        text-align: center;
        margin-right: .5rem;
        padding-top: .5rem;
        position: relative;

        .wenjianjia {
          width: 1.5rem;
          height: 1.5rem;
        }

        .btn-text {
          margin-top: -0.4rem;
          height: 1rem;
          font-size: 0.56rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FF4949;
          line-height: 1rem;
        }

        .upload-input {
          position: absolute;
          top: 0;
          left: 0;
          width: 3rem;
          height: 3rem;
          z-index: 999;
          opacity: 0;
        }
      }
    }

    .line {
      width: 93%;
      height: 1px;
      margin: 0.5rem auto;
      background: #f1f1f1;
    }

    .file-list {
      padding: 0.2rem 0.5rem;
      display: flex;
      justify-content: space-between;

      .file-name {
        display: flex;
        align-items: center;

        .icon-pdf {
          width: 0.6rem;
          height: 0.6rem;
        }

        font-size: 0.5rem;
        font-family: PingFangSC-Regular,
        PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      .delet {
        font-size: 0.6rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FF4949;
        padding: 2px 10px;
        border: 1px solid #FF4949;
        border-radius: 0.5rem;
      }
    }
  }
}
</style>