<template>
  <div class="brand-wrapper" id="brand">
    <section-title :maxTitle="'品牌矩阵'" :minTitle="'Brand matrix'" />
    <div class="brand-detail">
      <div class="brand-detail-item">
        <div class="brand-detail-position">
          <div class="brand-detail-title">驿舍</div>
          <div class="brand-detail-des">高品质员工住宿解决方案</div>
        </div>
      </div>
      <div class="brand-detail-item">
        <div class="brand-detail-position">
          <div class="brand-detail-title">怡居</div>
          <div class="brand-detail-des">智能化城市青年公寓</div>
        </div>
      </div>
      <div class="brand-detail-item">
        <div class="brand-detail-position">
          <div class="brand-detail-title">君悦</div>
          <div class="brand-detail-des">有温度的都市生活空间</div>
        </div>
      </div>
      <div class="brand-detail-item">
        <div class="brand-detail-position">
          <div class="brand-detail-title">美庭</div>
          <div class="brand-detail-des">精品轻奢服务公寓</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SectionTitle from '../components/sectionTitle'
export default {
  name: 'Brand',
  components: {
    SectionTitle
  }
}
</script>

<style lang="less" scoped>
.brand-wrapper {
  width: 14rem;
  margin: 0 auto;
  .brand-detail {
    width: 100%;
    margin-top: 0.6rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .brand-detail-item {
      width: 6.9rem;
      height: 6rem;
      margin-top: 0.2rem;
      cursor: pointer;
      position: relative;
      .brand-detail-position {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 0;
        color: #ffffff;
        font-weight: 500;
        font-family: PingFangSC-Medium, PingFang SC;
        background-color: rgba(0, 0, 0, 0.2);
        .brand-detail-title {
          height: 0.84rem;
          line-height: 0.84rem;
          font-size: 0.6rem;
          padding-top: 0.1rem;
        }
        .brand-detail-des {
          height: 0.66rem;
          line-height: 0.66rem;
          font-size: 0.48rem;
          padding-bottom: 0.1rem;
        }
      }

      &:nth-child(1) {
        background-image: url('../../assets/images/m-brand-1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      &:nth-child(2) {
        background-image: url('../../assets/images/m-brand-2.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      &:nth-child(3) {
        background-image: url('../../assets/images/m-brand-3.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      &:nth-child(4) {
        background-image: url('../../assets/images/m-brand-4.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>