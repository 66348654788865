<template>
  <div class="header-banner">
    <div class="header-nav">
      <img class="banner-logo" src="@/assets/images/logo.png" alt="logo" />
      <img class="banner-icon" @click="handleOpen" src="@/assets/images/banner-icon.png" alt="icon" />
    </div>
    <div class="nav-list" ref="nav" style="height:0px; transition: all 0.3s;">
      <div
        class="nav-list-item"
        v-for="(item,index) in navList"
        :key="index"
        @click="handleChange(index)"
      >
        <a class="nav-a" :class="{active: current === index}" :href="item.herf">{{item.name}}</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Header',
  data () {
    return {
      open: false,
      current: 0,
      navList: [{ name: '首页', herf: '#top' },
      { name: '集团介绍', herf: '#introduction' },
      { name: '品牌矩阵', herf: '#brand' },
      { name: '服务介绍', herf: '#service' },
      { name: '金融支持', herf: '#financial' },
      { name: '商务合作', herf: '#contact' }],

    }
  },
  methods: {
    handleOpen () {
      this.open = !this.open
      if (this.open) {
        this.$refs['nav'].style.height = '8.4rem'
      } else {
        this.$refs['nav'].style.height = '0px'
      }
    },
    handleChange (index) {
      this.current = index
    }
  }

}
</script>
<style lang="less" scoped>
.header-banner {
  width: 100vw;
  height: 8.4rem;
  background-image: url('../../assets/images/home-new.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  .header-nav {
    width: 100%;
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    .banner-logo {
      margin-left: 0.5rem;
      width: 3.6rem;
      height: 1.05rem;
    }
    .banner-icon {
      margin-right: 1.56rem;
      width: 0.8rem;
      height: 0.64rem;
    }
  }
  .nav-list {
    position: absolute;
    top: 1.8rem;
    right: 0.5rem;
    width: 3.16rem;
    overflow: hidden;
    background: #ffffff;
    box-shadow: 0px 4px 19px 0px rgba(235, 235, 235, 0.5);
    .nav-list-item {
      margin-top: 0.5rem;
      text-align: center;
      width: 100%;
      height: 0.8rem;
      font-size: 0.56rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 0.8rem;
      & > .active {
        color: #f82626;
      }
    }
  }
}
</style>