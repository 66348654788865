<template>
  <div class="main-page">
    <header-banner />
    <introduction />
    <brand />
    <service />
    <financial />
    <contact />
  </div>
</template>

<script>
import Brand from './M-Brand'
import Service from './M-Service'
import Financial from './M-Financial'
import HeaderBanner from './M-Header'
import Introduction from './M-Introduction.vue'
import Contact from './M-Contact'
export default {
  data () {
    return
  },
  components: {
    HeaderBanner,
    Brand,
    Service,
    Financial,
    Introduction,
    Contact
  }

}
</script>

<style>
</style>