<template>
  <div id="app-content">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  data () {
    return {
    }
  },
  mounted () {

  },
  components: {

  }

}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  max-width: 750px;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: #333333;
}

#app-content {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
