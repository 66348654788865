<template>
  <div class="section-title-wrapper">
    <div class="section-title">
      <span class="section-title-big" :class="{light:isLight}">{{maxTitle}}</span>
      <span class="section-title-small" :class="{light:isLight}">{{minTitle}}</span>
    </div>
    <div class="section-line" :class="{light:isLight}"></div>
  </div>
</template>

<script>
export default {
  props: {
    maxTitle: {
      type: String
    },
    minTitle: {
      type: String
    },
    isLight: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.section-title-wrapper {
  margin-top: 1.2rem;
  width: 100%;

  .section-title {
    .section-title-big {
      height: 0.84rem;
      font-size: 0.6rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 0.84rem;
      &.light {
        color: #ffffff;
      }
    }
    .section-title-small {
      padding-left: 0.1rem;
      height: 0.66rem;
      font-size: 0.48rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 0.66rem;
      &.light {
        color: #ffffff;
      }
    }
  }
  .section-line {
    margin-top: 0.2rem;
    width: 0.56rem;
    height: 0.08rem;
    background: #333333;
    border-radius: 0.02rem;
    &.light {
      background: #ffffff;
    }
  }
}
</style>