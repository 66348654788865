<template>
  <div class="financial-wrapper" id="financial">
    <section-title :maxTitle="'金融支持'" :minTitle="'Financial support'" />
    <div class="financial-title">“房东合伙人”长租公寓专项资金赋能</div>
    <div class="financial">
      <div class="financial-item">
        <div class="financial-item-icon icon-1"></div>
        <div class="financial-item-des">
          <p class="des-title">业主“装修贷</p>
          <p class="des-text">单一项目最高2,000万,无需抵押 最长5年</p>
        </div>
      </div>
      <div class="financial-item">
        <div class="financial-item-icon icon-2"></div>
        <div class="financial-item-des">
          <p class="des-title">承租方“经营宝”</p>
          <p class="des-text">
            单一项目最高1,000万,有租约即可申请
            <br />无需抵押 不用转租
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from '../components/sectionTitle'
export default {
  name: 'financial',
  components: {
    SectionTitle
  }
}
</script>

<style lang="less" scoped>
.financial-wrapper {
  width: 14rem;
  margin: 0 auto;
  .financial-title {
    margin-top: 1rem;

    width: 100%;
    text-align: center;
    font-size: 0.56rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  .financial {
    background: #ffffff;
    box-shadow: 0px 4px 19px 0px rgba(210, 210, 210, 0.5);
    margin-top: 0.6rem;
    .financial-item {
      width: 100%;
      height: 4.2rem;
      border-bottom: 0.02rem solid #ededed;
      display: flex;
      align-items: center;
      .financial-item-icon {
        width: 1.76rem;
        height: 1.76rem;
        margin-left: 0.72rem;
        margin-right: 0.76rem;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        &.icon-1 {
          background-image: url('../../assets/images/financial-1.png');
        }
        &.icon-2 {
          background-image: url('../../assets/images/financial-2.png');
        }
      }
      .financial-item-des {
        .des-title {
          font-size: 0.56rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        .des-text {
          font-size: 0.48rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
</style>