<template>
  <div class="service-wrapper" id="service">
    <section-title :maxTitle="'服务介绍'" :minTitle="'Service Introduction'" />
    <div class="service">
      <div class="service-item">
        <img class="service-item-icon" src="@/assets/images/serve-icon-1.png" alt="图标" />
        <div class="service-item-des">
          <p class="des-title">项目定位</p>
          <p class="des-text">
            26维数据模型 + 10年运营
            <br />经验精准定位客群，开业
            <br />即满租全年无空置
          </p>
        </div>
      </div>
      <div class="service-item">
        <img class="service-item-icon" src="@/assets/images/serve-icon-2.png" alt="图标" />
        <div class="service-item-des">
          <p class="des-title">供应链整合</p>
          <p class="des-text">
            定制化方案 + 模块化施工
            <br />整合供应链 全网最低价
            <br />消灭无效投资 缩短建设工期
          </p>
        </div>
      </div>
      <div class="service-item">
        <img class="service-item-icon" src="@/assets/images/serve-icon-3.png" alt="图标" />
        <div class="service-item-des">
          <p class="des-title">智能公寓升级</p>
          <p class="des-text">
            可视化全平台管理终
            <br />端 + 智能公寓硬件部署
            <br />管理省心 财务清晰
          </p>
        </div>
      </div>
      <div class="service-item">
        <img class="service-item-icon" src="@/assets/images/serve-icon-4.png" alt="图标" />
        <div class="service-item-des">
          <p class="des-title">品牌化标准运营</p>
          <p class="des-text">
            50+项SOP运营标准
            <br />全方位专业运营提高
            <br />租客满意度、续租率
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from '../components/sectionTitle'
export default {
  name: 'Service',
  components: {
    SectionTitle
  }
}
</script>

<style lang="less" scoped>
.service-wrapper {
  width: 14rem;
  margin: 0 auto;

  .service {
    background: #ffffff;
    box-shadow: 0px 4px 19px 0px rgba(210, 210, 210, 0.5);
    margin-top: 0.6rem;
    display: flex;
    flex-wrap: wrap;
    .service-item {
      box-sizing: border-box;
      width: 50%;
      height: 8.8rem;
      text-align: center;
      &:nth-child(1) {
        border-right: 2px solid #f1f1f1;
        border-bottom: 2px solid #f1f1f1;
      }
      &:nth-child(2) {
        border-bottom: 2px solid #f1f1f1;
      }
      &:nth-child(3) {
        border-right: 2px solid #f1f1f1;
      }

      .service-item-icon {
        padding-top: 1rem;
        width: 1.76rem;
        height: 1.76rem;
      }
      .service-item-des {
        .des-title {
          padding-bottom: 0.4rem;
          font-size: 0.56rem;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        .des-text {
          font-size: 0.48rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 0.8rem;
        }
      }
    }
  }
}
</style>